var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loader,"rounded":"sm","variant":"dark","opacity":0.1}},[_c('b-card',{attrs:{"title":"Create New Type"}},[_c('validation-observer',{ref:"createTypeValidator"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","name":"name","placeholder":"Enter type"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Image","label-for":"image"}},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"id":"image","accept":".jpg, .png, .gif .svg"},model:{value:(_vm.form.image),callback:function ($$v) {_vm.$set(_vm.form, "image", $$v)},expression:"form.image"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Type","label-for":"type"}},[_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"options":_vm.options},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Popup Description","label-for":"popup_description"}},[_c('validation-provider',{attrs:{"name":"popup_description"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-textarea',{attrs:{"id":"popup_description","name":"popup_description","placeholder":""},model:{value:(_vm.form.popup_description),callback:function ($$v) {_vm.$set(_vm.form, "popup_description", $$v)},expression:"form.popup_description"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Popup Image","label-for":"popup_image"}},[_c('validation-provider',{attrs:{"name":"popup_image"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".jpg, .png, .gif .svg"},model:{value:(_vm.form.popup_image),callback:function ($$v) {_vm.$set(_vm.form, "popup_image", $$v)},expression:"form.popup_image"}}),_c('span',{staticClass:"text-danger error-msg"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v(" Create ")])],1)])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }