<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-overlay
          :show="loader"
          rounded="sm"
          variant="dark"
          :opacity="0.1"
        >
          <b-card title="Create New Type">
            <validation-observer ref="createTypeValidator">
              <b-form @submit.prevent="onSubmit">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Name"
                      label-for="name"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="name"
                        rules="required"
                      >
                        <b-form-input
                          id="name"
                          v-model="form.name"
                          name="name"
                          placeholder="Enter type"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Image"
                      label-for="image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="image"
                        rules="required"
                      >
                        <b-form-file
                          id="image"
                          v-model="form.image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Type"
                      label-for="type"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="type"
                        rules="required"
                      >
                        <b-form-select
                          v-model="form.type"
                          :options="options"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Popup Description"
                      label-for="popup_description"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="popup_description"
                      >
                        <b-textarea
                          id="popup_description"
                          v-model="form.popup_description"
                          name="popup_description"
                          placeholder=""
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group
                      label="Popup Image"
                      label-for="popup_image"
                    >
                      <validation-provider
                        v-slot="{ errors }"
                        name="popup_image"
                      >
                        <b-form-file
                          v-model="form.popup_image"
                          accept=".jpg, .png, .gif .svg"
                        />
                        <span class="text-danger error-msg">{{ errors[0] }}</span>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <div class="d-flex justify-content-end">
                      <b-button
                        variant="primary"
                        type="submit"
                      >
                        Create
                      </b-button>
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </validation-observer>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const macLaptopPcModule = createNamespacedHelpers('macLaptopPc')

export default {
  data() {
    return {
      loader: false,
      form: {
        name: '',
        type: null,
        image: [],
        is_popup_required: 1,
        popup_description: '',
        popup_image: null,
      },
      options: [
        { value: null, text: 'Please select type', disabled: true },
        { value: 'other', text: 'Other' },
        { value: 'apple', text: 'Apple' },
      ],
    }
  },
  methods: {
    ...macLaptopPcModule.mapActions(['ADD_TYPE']),

    onSubmit() {
      this.$refs.createTypeValidator.validate().then(async success => {
        if (success) {
          try {
            this.loader = true
            const formData = new FormData()
            formData.append('name', this.form.name)
            formData.append('type', this.form.type)
            formData.append('image', this.form.image)
            formData.append('is_popup_required', this.form.is_popup_required)
            formData.append('popup_description', this.form.popup_description)
            if (this.form.popup_image) {
              formData.append('popup_image', this.form.popup_image)
            }
            const resp = await this.ADD_TYPE(formData)
            if (resp) {
              this.resetForm()
              this.$nextTick(() => {
                this.$refs.createTypeValidator.reset()
              })
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Type created!',
                  icon: 'checkIcon',
                  variant: 'success',
                  text: 'The type has been created successfully!',
                },
              })
            }
            this.loader = false
          } catch (error) {
            this.loader = false
          }
        }
      })
    },
    resetForm() {
      this.form.name = ''
      this.form.type = null
      this.form.image = []
      this.form.popup_description = ''
      this.form.popup_image = null
    },
  },
}
</script>
